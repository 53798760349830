<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>조치대상관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
				<div class="heading-elements">
					<ul class="icons-list">
						<li><a @click="pannelHidden" data-action="collapse"></a></li>
					</ul>
				</div>
			</div> -->
			<div class="panel-body" style="width: 1000px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 250px">
							<div class="form-group">
								<label style="width: 85px">{{ detailsFieldMap.targetId }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									style="width: 110px"
									:placeholder="detailsFieldMap.targetId"
									v-model="searchVM.targetId"
									:maxlength="maxLength.targetId"
									@keyup="checkItHasOnlyCapital('targetSearch', $event)"
								/>
							</div>
						</div>
						<div class="col-md-3" style="width: 300px">
							<div class="form-group">
								<label style="width: 65px">{{ detailsFieldMap.targetNm }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 250px"
									:placeholder="detailsFieldMap.targetNm"
									v-model="searchVM.targetNm"
									:maxlength="maxLength.targetNm"
								/>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								조치대상관리 정보
							</legend>
							<div class="col-md-6 inputDiv1">
								<!-- 조치대상 코드 -->
								<jarvis-field
									:label="detailsFieldMap.targetId"
									id="firstInputCreate"
									required="true"
									:disabled="!isCreateMode"
									field="detailsItem.targetId"
									v-model="detailsItem.targetId"
									data-vv-name="detailsItem.targetId"
									v-validate="validationRule.detailsItem.targetId"
									:maxLength="maxLength.targetId"
									@keyup="checkItHasOnlyCapital('tagetDetail', $event)"
								></jarvis-field>
								<!-- 조치대상 명 -->
								<jarvis-field
									:label="detailsFieldMap.targetNm"
									id="firstInputEdit"
									required="true"
									field="detailsItem.targetNm"
									v-model="detailsItem.targetNm"
									data-vv-name="detailsItem.targetNm"
									v-validate="validationRule.detailsItem.targetNm"
									:maxLength="maxLength.targetNm"
								></jarvis-field>
								<!-- 조치대상 영문명 -->
								<jarvis-field
									:label="detailsFieldMap.targetEngNm"
									field="detailsItem.targetEngNm"
									v-model="detailsItem.targetEngNm"
									data-vv-name="detailsItem.targetEngNm"
									v-validate="validationRule.detailsItem.targetEngNm"
								></jarvis-field>
								<!-- 조치대상 메모 -->
								<!-- <jarvis-field
									:label="detailsFieldMap.memo"
									field="detailsItem.memo"
									v-model="detailsItem.memo"
									data-vv-name="detailsItem.memo"
									v-validate="validationRule.detailsItem.memo"
									:maxLength="maxLength.memo"
								></jarvis-field> -->
								<div class="row col-md-12" style="margin-left: 0px; padding-right: 0px">
									<label class="col-md-3 title" style="text-align: center; margin-top: 8px; padding: 0px">{{ detailsFieldMap.memo }}:</label>
									<div class="col-md-9" style="padding: 0px">
										<textarea
											class="form-control"
											rows="3"
											cols="40"
											v-model="detailsItem.memo"
											field="detailsItem.memo"
											data-vv-name="detailsItem.memo"
											style="resize: none"
											:maxlength="maxLength.memo"
										></textarea>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>
						</fieldset>
					</div>

					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const targetUrl = apiIndex.target;

// var pageParam = {
// 	mstCodeOptions: 1,
// };

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		targetId: 'max:50|required',
		targetNm: 'max:50|required',
		targetEngNm: 'max:50',
		memo: 'max:4000',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

const maxLength = {
	targetId: '3',
	targetNm: '50',
	targetEngNm: '50',
	memo: '4000',
};
_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	data: () => ({
		//API목록
		apiUrl: {
			excelApi: targetUrl.exlTarget,
			pageListApi: targetUrl.inqTarget,
			detailsApi: targetUrl.target,
		},
		//검색VM
		searchVM: {
			targetId: '',
			targetNm: '',
		},
		//적용된 검색조건
		searchState: {},
		//그리드설정
		gridColumns: [
			{ field: 'targetId', title: '조치대상 코드', width: '10%', align: 'center' },
			{ field: 'targetNm', title: '조치대상명', width: '20%' },
			{ field: 'targetEngNm', title: '조치대상 영문명', width: '20%' },
			{ field: 'memo', title: '메모', width: '20%', overFlowHidden: true },
			{ field: 'regDtm', title: '등록일', dataFormat: 'YYYY-MM-DD', width: '10%', align: 'center' },
			{ field: 'updDtm', title: '수정일', dataFormat: 'YYYY-MM-DD', width: '10%', align: 'center' },
		],
		//상세필드명맵
		detailsFieldMap: {
			targetId: '조치대상 코드',
			targetNm: '조치대상명',
			targetEngNm: '조치대상 영문명',
			memo: '메모',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '최종 수정일',
			updUser: '최종 수정자',
		},
		//인풋 최댓값 설정
		maxLength,
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				if (this.detailsItem.isChangePassword) {
					rule = $.extend(true /*deep*/, { password: 'required|min:4|max:20' }, detailsValidationRule.edit);
				} else {
					rule = detailsValidationRule.edit;
				}
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		isCreateMode() {
			let element = document.getElementsByClassName('inputDiv1')[0];
			if (this.isCreateMode) {
				element.classList.add('col-lg-offset-3');
			} else {
				element.classList.remove('col-lg-offset-3');
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		putValueInInput(data, value) {
			if (data == 'targetSearch') {
				this.searchVM['targetId'] = value;
			} else {
				this.detailsItem['targetId'] = value;
			}
		},
		changeSmallLetterToCap(value) {
			let valueArr = value.split('');
			let lengthOfvalue = valueArr.length;
			for (let i = 0; i < lengthOfvalue; i++) {
				if (valueArr[i].charCodeAt() >= 97 && valueArr[i].charCodeAt() <= 122) {
					valueArr[i] = value[i].toUpperCase();
				}
			}
			return valueArr.join('');
		},
		checkItHasOnlyCapital(data, event) {
			let target = event.target;
			target.value = this.changeSmallLetterToCap(target.value);
			target.value = this.$jarvisExtention.inputDataController.onlyCapital(target.value);
			this.putValueInInput(data, target.value);
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			/*console.log(JSON.stringify(this.searchVM))*/

			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			/*console.log( this.searchState );*/
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '조치대상관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.startEdit(selectedRowItem.targetId);
				setTimeout(() => this.focusFirstInput('firstInputEdit'), 500);
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				targetId: '',
				targetNm: '',
				targetEngNm: '',
				memo: '',
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//데이터 수정 시작
		startEdit(targetId) {
			const closure = this;
			this.$axios
				.get(closure.apiUrl.pageListApi + '/' + targetId)
				.then(function (response) {
					response;
					closure.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
					closure.detailsItem = $.extend(true /*deep*/, {}, response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//신규저장
		createData() {
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					console.log('validation.errors: ', closure.errors);
					return;
				}
				closure.$axios
					.post(closure.apiUrl.detailsApi, closure.detailsItem)
					.then(function (response) {
						response;
						closure.loadGrid();
					})
					.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					console.log('validation.errors: ', closure.errors);
					return;
				}
				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					const targetId = closure.detailsItem.targetId;
					closure.$axios
						.put(closure.apiUrl.detailsApi + '/' + targetId, closure.detailsItem)
						.then(function (response) {
							response;
							closure.loadGrid();
						})
						.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('대상물과의 매핑정보도 삭제됩니다. \n해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			const targetId = this.detailsItem.targetId;
			const closure = this;
			this.$axios
				.delete(closure.apiUrl.detailsApi + '/' + targetId)
				.then(function (response) {
					response;
					closure.loadGrid();
				})
				.catch(axiosExtention.buildErrorHandler());
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	margin-top: 5px;
}
#rootVM > .panel .panel-body .form-group input {
	max-width: 200px;
	margin-left: 15px;
}
</style>
